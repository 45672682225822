import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import { toast, ToastContainer } from 'react-toastify'
import CircularProgress from '@material-ui/core/CircularProgress'
import SEO from '../../components/SEO'
import Layout from '../../components/Layout'
import Label from '../../components/Form/label'
import Input from '../../components/Form/input'
import InputMask from '../../components/Form/mask'
import Select from '../../components/Form/select'
import Option from '../../components/Form/option'
import Checkbox from '../../components/Form/checkbox'
import ErrorMessage from '../../components/ErrorMessage'
import Button from '../../components/Buttons/button'
import NavLink from '../../components/Buttons/'
import BannerFootID from '../../components/FootId/Banner'
import { maskTelefone } from '../../helpers/util'
import { GENEROS } from '../../constants/form'
import { ditoIdentify, ditoTrack } from '../../helpers/dito'
import {
  fetchData,
  requestOptions,
  URL_AGENDAMENTO,
  URL_CIDADES,
  URL_ESTADOS,
  URL_FILIAIS,
} from '../../services/API'

import { pin } from '../../images/foot-id'

import bannerAgendamento from '../../assets/images/foot-id/banner-agendamento.jpg'

import * as S from './styles'
import { graphql } from 'gatsby'
import { useI18next } from 'gatsby-plugin-react-i18next'

const AgendamentoFootIdPage = () => {
  const { language } = useI18next()
  const [ufs, setUfs] = useState([])
  const [uf, setUf] = useState('')
  const [cidades, setCidades] = useState([])
  const [cidade, setCidade] = useState('')
  const [filiais, setFiliais] = useState([])
  const [lojaId, setLojaId] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [erroCampos, setErroCampos] = useState({})
  const [aceiteTermosUso, setAceiteTermosUso] = useState(false)
  const [sucessoAgendamento, setSucessoAgendamento] = useState(false)
  const [filialSelecionada, setFilialSelecionada] = useState([])
  const [dadosAgendamento, setDadosAgendamento] = useState({
    nome: '',
    email: '',
    genero: '',
    data_nascimento: '',
    telefone: '',
    numero_calcado: '',
    data: '',
  })

  useEffect(() => {
    async function pegaUf() {
      const { data, error } = await fetchData(URL_ESTADOS(language))

      if (error) {
        toast.error(error.message)
        return
      }

      setUfs(data)
    }

    pegaUf()
  }, [])

  useEffect(() => {
    async function pegaCidade() {
      const { data, error } = await fetchData(`${URL_CIDADES(language)}/${uf}`)

      if (error) {
        toast.error(error.message)
        return
      }

      setCidades(data)
    }

    if (uf !== '') {
      pegaCidade()
    }
  }, [uf])

  useEffect(() => {
    async function pegaFiliais() {
      const { data, error } = await fetchData(
        `${URL_FILIAIS(language)}/${cidade}`
      )

      if (error) {
        toast.error(error.message)
        return
      }

      setFiliais(data)
    }

    if (cidade !== '') {
      pegaFiliais()
    }
  }, [cidade])

  function handleChangeForm(event) {
    const value = event.target.value

    setDadosAgendamento({
      ...dadosAgendamento,
      [event.target.name]: value,
    })
  }

  async function handleSubmit(event) {
    event.preventDefault()

    const dados = {
      ...dadosAgendamento,
      aceiteTermosUso,
      loja_id: lojaId,
    }

    try {
      setLoading(true)

      const response = await fetch(
        URL_AGENDAMENTO(language),
        requestOptions(dados)
      )
      const data = await response.json()

      if (!response.ok && response.status === 422) {
        setErroCampos(data.errors || {})
        toast.error(data.message)
        return
      }

      if (!response.ok) {
        throw new Error(data.message)
      }

      setErroCampos({})
      setSucessoAgendamento(true)
      handleResetForm()

      const filtraFilialSelecionada = filiais.filter(
        (filial) => filial.id === lojaId
      )

      setFilialSelecionada(filtraFilialSelecionada)

      const ditoAgendamentoData = {
        email_loja: filtraFilialSelecionada[0].email,
        id_cliente: data.hashid,
        data_agendamento: dadosAgendamento.data,
      }

      const ditoIdentifyData = {
        nome: dadosAgendamento.nome,
        email: dadosAgendamento.email,
        genero: dadosAgendamento.genero,
        data_nascimento: dadosAgendamento.data_nascimento,
        telefone: dadosAgendamento.telefone,
        numero_calcado: dadosAgendamento.numero_calcado,
        aceiteTermosUso,
      }

      ditoIdentify(ditoIdentifyData)
      ditoTrack('agendou-foot-id', ditoAgendamentoData)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  function handleResetForm() {
    setDadosAgendamento({
      nome: '',
      email: '',
      genero: '',
      data_nascimento: '',
      telefone: '',
      numero_calcado: '',
      data: '',
    })
    setUf('')
    setCidades([])
    setFiliais([])
    setLojaId(undefined)
    setFilialSelecionada([])
    setAceiteTermosUso(false)
  }

  function MontaEnderecoSucesso() {
    return (
      <S.EnderecoSucesso>
        <img src={pin} alt="Como chegar" />
        <span>
          <p>
            {filialSelecionada[0].endereco} - {filialSelecionada[0].bairro}
            <br />
            {filialSelecionada[0].cep}
          </p>
          {filialSelecionada[0].url_navegacao !== null && (
            <NavLink
              element="a"
              href={filialSelecionada[0].url_navegacao}
              target="_blank"
              rel="nofollower noreferrer noopener"
            >
              como chegar
            </NavLink>
          )}
        </span>
      </S.EnderecoSucesso>
    )
  }

  //Remove acentos e espaçamentos do item da array GENEROS para popular o value do gênero no formulário.
  function formataString(string) {
    const newString = string.toLowerCase().replaceAll(" ", "-").replaceAll("/[^a-zA-Z0-9 ]/g", "").replaceAll(":", "").replaceAll("!", "").normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    return newString;
  }

  return (
    <>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <Layout>
        <SEO
          title="FOOT ID"
          description="Deixe seus pés falarem: Sua pisada pode definir tudo sobre você e a sua corrida"
        />

        <BannerFootID
          texto={`agende <br /> <strong>seu teste</strong>`}
          src={bannerAgendamento}
        />

        <S.Agendamento>
          {sucessoAgendamento ? (
            <S.SucessoAgendamento>
              <h1>agendamento concluído com sucesso!</h1>
              <p>
                Sua avaliação do ASICS FOOT-ID já está agendada. Reserve a data
                para não perder e lembre-se que leva no máximo 5 minutos para
                fazer o teste, receber o resultado e as instruções da nossa
                equipe.
              </p>
              <p>
                Leve tênis e roupas confortáveis para realizar o teste e
                confirme quais os melhores caminhos para chegar até a loja da
                ASICS selecionada.
              </p>
              <h2>Confira o endereço e como chegar:</h2>
              {!loading && MontaEnderecoSucesso()}
            </S.SucessoAgendamento>
          ) : (
            <S.FormAgendamento onSubmit={handleSubmit}>
              <h1>dados pessoais</h1>

              <S.WrapperFormAgendamento>
                <div className="item">
                  <Label texto="nome">Nome</Label>
                  <Input
                    type="text"
                    id="nome"
                    placeholder="Seu nome"
                    onChange={handleChangeForm}
                    value={dadosAgendamento.nome}
                    className={erroCampos.nome && 'erro'}
                  />
                  {erroCampos.nome && (
                    <ErrorMessage mensagem={erroCampos.nome} />
                  )}
                </div>
                <div className="item">
                  <Label texto="email">E-mail</Label>
                  <Input
                    type="email"
                    id="email"
                    placeholder="Seu e-mail"
                    onChange={handleChangeForm}
                    value={dadosAgendamento.email}
                    className={erroCampos.email && 'erro'}
                  />
                  {erroCampos.email && (
                    <ErrorMessage mensagem={erroCampos.email} />
                  )}
                </div>
                <div className="item">
                  <Label texto="genero">Gênero</Label>
                  <Select
                    id="genero"
                    onChange={handleChangeForm}
                    value={dadosAgendamento.genero}
                    className={erroCampos.genero && 'erro'}
                  >
                    <Option value="">
                      SELECIONE O GÊNERO QUE VOCÊ SE IDENTIFICA
                    </Option>
                    {GENEROS.map((val) => (
                      <Option key={`genero-${val}`} value={val === "Prefiro não responder" ? "nao-responder" : formataString(val)}>
                        {val}
                      </Option>
                    ))}
                  </Select>
                  {erroCampos.genero && (
                    <ErrorMessage mensagem={erroCampos.genero} />
                  )}
                </div>
                <div className="item">
                  <Label texto="data_nascimento">Data de Nascimento</Label>
                  <Input
                    type="date"
                    id="data_nascimento"
                    placeholder=""
                    onChange={handleChangeForm}
                    value={dadosAgendamento.data_nascimento}
                  />
                </div>
                <div className="item">
                  <Label texto="telefone">Telefone</Label>
                  <InputMask
                    type="tel"
                    id="telefone"
                    placeholder="Seu telefone"
                    mask={maskTelefone}
                    className={cx('mask-input', { erro: erroCampos.telefone })}
                    onChange={handleChangeForm}
                    value={dadosAgendamento.telefone}
                  />
                  {erroCampos.telefone && (
                    <ErrorMessage mensagem={erroCampos.telefone} />
                  )}
                </div>
                <div className="item">
                  <Label texto="numero_calcado">Número do calçado</Label>
                  <Input
                    type="text"
                    id="numero_calcado"
                    placeholder="Número que você calça"
                    onChange={handleChangeForm}
                    value={dadosAgendamento.numero_calcado}
                  />
                </div>
              </S.WrapperFormAgendamento>

              <h2>escolha uma loja e data disponível</h2>
              <h3>
                A avaliação pode ser realizada em qualquer horário e leva por
                volta de 5 minutos
              </h3>

              <S.WrapperFormAgendamento>
                <div className="item">
                  <Label texto="estado">estado</Label>
                  <Select
                    id="estado"
                    value={uf}
                    onChange={({ target }) => setUf(target.value)}
                  >
                    <Option value="">SELECIONAR ESTADO</Option>
                    {ufs.map((uf) => (
                      <Option key={uf} value={uf}>
                        {uf}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="item">
                  <Label texto="cidade">cidade</Label>
                  <Select
                    id="cidade"
                    disabled={cidades.length === 0 && true}
                    value={cidade}
                    onChange={({ target }) => setCidade(target.value)}
                  >
                    <Option value="">SELECIONAR CIDADE</Option>
                    {cidades.map((cidade) => (
                      <Option key={cidade.nome} value={cidade.id}>
                        {cidade.nome}
                      </Option>
                    ))}
                  </Select>
                </div>
                <div className="item">
                  <Label texto="loja_id">selecione a filial desejada</Label>
                  <Select
                    id="loja_id"
                    disabled={filiais.length === 0 && true}
                    onChange={({ target }) => setLojaId(Number(target.value))}
                    value={lojaId}
                    className={erroCampos.loja_id && 'erro'}
                  >
                    <Option value="">SELECIONAR FILIAL</Option>
                    {filiais.map((loja) => (
                      <Option key={loja.nome} value={loja.id}>
                        {loja.nome} - {loja.endereco} - {loja.numero}
                      </Option>
                    ))}
                  </Select>
                  {erroCampos.loja_id && (
                    <ErrorMessage mensagem={erroCampos.loja_id} />
                  )}
                </div>
                <div className="item">
                  <Label texto="data">Data de agendamento</Label>
                  <Input
                    type="date"
                    id="data"
                    onChange={handleChangeForm}
                    value={dadosAgendamento.data}
                    min={
                      new Date(new Date().setDate(new Date().getDate() + 1))
                        .toISOString()
                        .split('T')[0]
                    }
                    className={erroCampos.data && 'erro'}
                    placeholder="DD/MM/AAAA"
                  />
                  {erroCampos.data && (
                    <ErrorMessage mensagem={erroCampos.data} />
                  )}
                </div>
                <div className="item">
                  <Checkbox
                    id="politica"
                    name="politica"
                    value={aceiteTermosUso}
                    checked={aceiteTermosUso === true}
                    onChange={() => setAceiteTermosUso(!aceiteTermosUso)}
                  />
                  {erroCampos.aceiteTermosUso && (
                    <ErrorMessage mensagem={erroCampos.aceiteTermosUso} />
                  )}
                </div>
                <div className="item">
                  <S.MensagemHorarioFuncionamento>
                    *feriados e outros eventos podem modificar o horário de
                    funcionamento
                  </S.MensagemHorarioFuncionamento>
                </div>
                <div className="item">
                  <Button
                    type="submit"
                    disabled={!!(loading || !aceiteTermosUso)}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={25} className="circleLoading" />{' '}
                        Enviando
                      </>
                    ) : (
                      'Enviar Agendamento'
                    )}
                  </Button>
                </div>
              </S.WrapperFormAgendamento>
            </S.FormAgendamento>
          )}
        </S.Agendamento>
      </Layout>
    </>
  )
}

export default AgendamentoFootIdPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
