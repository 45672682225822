import styled from 'styled-components'

export const BannerFootID = styled.section`
  background-color: var(--primary-color);
  background-position: center top;
  background-size: cover;
  height: 300px;
  width: 100%;

  @media (max-width: 767px) {
    background-image: none !important;
    height: 200px;
    padding: 0 15px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding: 0 15px;
  }
`

export const BannerWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 100%;
  width: 900px;

  h1 {
    color: #fff;
    font-size: 50px;
    font-style: italic;
    font-weight: 400;
    line-height: 50px;
    text-transform: uppercase;

    @media (max-width: 767px) {
      font-size: 42px;
      line-height: 44px;
    }
  }
`
